(function($){
	$(document).on('gform_post_render', attachGFormLabelEvents);

	function attachGFormLabelEvents() {
		var $inputs = $('.gform_body input[type="email"], .gform_body input[type="number"], .gform_body input[type="password"], .gform_body input[type="tel"], .gform_body input[type="text"], .gform_body textarea');

		// Attach event handlers
		$inputs.on('focus', function() {
			$(this).parents('.gfield').children('.gfield_label').addClass('shifted');
		});

		$inputs.on('blur', function() {
			if( $(this).val() == '' ) {
				$(this).parents('.gfield').children('.gfield_label').removeClass('shifted');
			}
		});

		$inputs.each(function() {
			var $label = $(this).parents('.gfield').children('.gfield_label');

			// Check for existing text in the fields
			if( $(this).val() != '' ) {
				$label.addClass('shifted');
			}

			// Apply correction for textarea
			if( $(this).hasClass('textarea') ) {
				$label.addClass('textarea-extra');
			}
		});

		// Return label for chekbox/radio/select to standard styling
		$('.ginput_container_select, .ginput_container_multiselect, .ginput_container_checkbox, .ginput_container_radio').prev('.gfield_label').addClass('normal');
	}

	$(document).on('click','.contact-footer .ginput_container_radio input, .page-template-page-contact #main .ginput_container_radio input',function(){
		$(this).parent().parent().find('.sim-checked').removeClass('sim-checked');
		$(this).parent().prevAll().find('input').addClass('sim-checked');
	});
}(jQuery));

// (function setUpFormSwitch() {
// 	var formField = document.querySelectorAll('.js-change-field-on-mobile--select');
// 	var breakpointDesktop = 992;
// 	var windowSize = window.innerWidth;
// 	for (var i = 0; i < formField.length; i++) {
// 		var myForm = formField[i];
// 		var formHTML = myForm.innerHTML;
// 		if (windowSize < breakpointDesktop) {
// 			formSwitchDropdown(myForm);
// 		}
// 	}
//
// })();
//
// function formLoop(forms) {
//
// }
//
// function formSwitchDropdown(form) {
// 	console.log(form);
//
// 	//Set up the Form Containers
// 	var container = form.querySelector('.ginput_container');
// 	container.classList.remove('ginput_container_radio');
// 	container.classList.add('ginput_container_select');
// 	var dropDownHolder = container.firstElementChild;
//
// 	// Begin Creating the New Node
// 	var newDropDown = document.createElement('select');
// 	var dropDownID = dropDownHolder.id;
// 	newDropDown.id = dropDownID;
// 	newDropDown.classList.add('gfield_select');
//
// 	//Get the Options and set the values and labels to an object so we can prepend them to our new drop down.
// 	container.appendChild(newDropDown);
// 	var options = dropDownHolder.children;
// 	var values = {};
// 	for (var i = 0; i < options.length; i++) {
// 		var option = options[i];
// 		var optionValue = option.children[0].value;
// 		var optionLabel = option.children[1].innerText;
// 		var optionID = option.children[0].id;
// 		var optionArray = {
// 			'label' : optionLabel,
// 			'value' : optionValue,
// 			'id': optionID
// 		};
// 		values[i] = optionArray;
// 	}
// 	for (var i in values) {
//
// 		var newNode = document.createElement('option');
//
// 		var value = values[i];
// 				console.log(value)
// 		newNode.id = value.id;
// 		newNode.value = value.value;
// 		newNode.innerText = value.label;
// 		newDropDown.appendChild(newNode);
// 	}
//
// //Destory the old radios
// dropDownHolder.remove();
//
// //Change the position of the label
// var label = container.nextElementSibling;
// label.remove();
// container.prepend(label);

// }
