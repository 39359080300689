(function($) {
	$.featherlight.defaults.afterContent = function(event){
		initPinchZoom();
	};

	$(document).on('ready',function() {
		$('.bb-gallery').each(function() {
			$(this).find('a').featherlightGallery();
		});
	});
	$(document).on('click','.featherlight-previous, .featherlight-next',function() {
		destroyPinchZoom();
		$('.featherlight-content img').on('load',function(){
			initPinchZoom();
		});
	});

	function initPinchZoom(){
		var featherLightImages = document.querySelectorAll('.featherlight-content > .featherlight-image');
		if (featherLightImages) {
			for (let i = 0; i < featherLightImages.length; i++) {
				var featherLightImage = featherLightImages[i];
				new PinchZoom(featherLightImage, {
					draggableUnzoomed: false,
					minZoom: 1,
				});
			}
		}
	}

	function destroyPinchZoom(){
		var $p = $('.pinch-zoom-container');
		$.each($p,function(){
			var img = $(this).find('img').removeAttr('style').unwrap();
			$(img).insertAfter('.featherlight-close');
			$(this).remove();
		});
	}
}(jQuery));
