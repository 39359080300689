(function($){
	var lastScrollTop = 0;
	var delta = 20;
	var header = $('#header');
	var navbarHeight = header.outerHeight();

	/* Document Events */
	$(document).ready(function() {
		initSliders();
		initHasScrolled();
		initFeatherlight();
		singleProjects();
		initLozad();
		initMasonry();
	});
	$(window).on('load',function() {
		generalHeightFixes();
	});
	$(window).on('resize',function() {
		generalHeightFixes();
	});

	/* Click Handlers */
	// Click to toggle main menu
	$(document).on('click', '.menu-button', function(){
		openMainMenu();
	});

	// Click outside of menu closes it
	$(document).on('click', 'html', function(e) {
		if( $(e.target).parents('.menu-outer-wrapper').length == 0 ) {
			closeMainMenu();
		}
	});

	// Click to close menu
	$(document).on('click', '#main-menu .close', function(){
		closeMainMenu();
	});

	// Project Nav
	$(document).on('click', '.single-project_post .section-link', function(e) {
		if( $(e.target).hasClass('deadzone') ) {
			return;
		}

		var section = $(this).attr('data-position');

		$('.single-project_post .project-nav .marker').attr('data-position', section);

		if( !$(this).hasClass('active') ) {
			$('.single-project_post .section-link, .single-project_post .project-section').removeClass('active');

			$(this).addClass('active');
			$('#' + section).addClass('active');

			$(window).off('scroll', function(){
				correctProjectNavSection();
			});

			setTimeout(function() {
				$(window).on('scroll', function(){
					correctProjectNavSection();
				});
			}, 1210);

			var yPosition = $('#' + section).offset().top;

			$('html, body').animate({scrollTop: yPosition - 135}, 1200);
		}
	});

	/* Key Handlers */
	$(document).on('keydown', function(e) {
		if( e.keyCode == 27 ) { //escape
			closeMainMenu();
		}
	});
	$('.hamburger').on('keydown', function(e) {
		if( e.keyCode == 13 ) { //enter
			openMainMenu();
		}
	});


	/* Functions */

	function initMasonry(){
		$('.masonry').masonry({
			itemSelector: '.masonry-item',
			columnWidth: '.masonry-item'
		});
	}

	function initLozad(){
		var observer = lozad();
		observer.observe();
	}

	var openMainMenu =  function() {
		var $menu = $('#main-menu');
		var $button = $('.menu-button');

		if( $button.hasClass('open') ) {
			$button.removeClass('open');
			$menu.removeClass('open');
			setTimeout(function() {
				$menu.hide();
			}, 190);
		} else {
			$(this).addClass('open');
			$menu.show();
			setTimeout(function() {
				$menu.addClass('open');
			}, 10);
		}
	};

	var closeMainMenu = function() {
		var $menu = $('#main-menu');

		$('.menu-button').removeClass('open');
		$menu.removeClass('open');

		setTimeout(function() {
			$menu.hide();
		}, 190);
	};

	function introSliderText(id){
		var html = $('.intro-slider .slide[data-slick-index="'+id+'"] .text').html();
		$('.intro-slider-text').animate({'opacity':0},500,function(){
			$('.intro-slider-text').html(html);
		});
	}

	function alignProjectNav() {
		if( window.scrollY >= window.innerHeight ) {
			$('.project-nav').addClass('fixed');
		} else {
			$('.project-nav').removeClass('fixed');
		}
	}

	function correctProjectNavSection() {
		if( window.scrollY >= window.innerHeight ) {
			var lastSection;
			var sections = ['intro', 'drawings', 'process', 'before_after'];

			sections.forEach(function(section) {
				var $el = $('#'+section);
				if($el.length){
					var yPosition = $el.offset().top;

					if( yPosition <= window.scrollY + 135 ) {
						lastSection = section;
					}
				}
			});

			if( lastSection ) {
				$('.single-project_post .project-nav .marker').attr('data-position', lastSection);

				$('.single-project_post .section-link, .single-project_post .project-section').removeClass('active');
				$('.single-project_post .section-link[data-position="' + lastSection + '"], #' + lastSection).addClass('active');
			}
		}
	}

	function singleProjects(){
		if( $('body').hasClass('single-project_post') ) {
			$(window).on('scroll', alignProjectNav);
			$(window).on('scroll', function(){
				correctProjectNavSection();
			});
			$(window).load(alignProjectNav);
			$(window).load(function(){
				correctProjectNavSection();
			});

			$('.intro-slider').each(function() {
				$(this).find('.slide > a').featherlightGallery();
			});
		}
	}

	function initFeatherlight(){
		$('a[rel="project-gallery"]').featherlightGallery();
		$('a[rel="project-gallery2"]').featherlightGallery();
	}

	function initSliders(){
		// Intro slider
		$('.intro-slider').on('init',function(event, slick){
			introSliderText(0);
			$('.intro-slider-text').animate({'opacity':1},500);
		});

		$('.intro-slider').on('beforeChange',function(event, slick, currentSlide, nextSlide){
			introSliderText(nextSlide);
		});

		$('.intro-slider').on('afterChange',function(event, slick, currentSlide, nextSlide){
			$('.intro-slider-text').animate({'opacity':1},500);
		});

		$('.intro-slider').slick({
			arrows:				true,
			prevArrow:			'<a href="#" class="slick-prev arrow lev-icon-left-arrow"><span class="screen-reader-text">Previous Slide</span></a>',
			nextArrow:			'<a href="#" class="slick-next arrow lev-icon-right-arrow"><span class="screen-reader-text">Next Slide</span></a>',
			autoplay:			false,
			dots:				false,
			fade:				false,
			centerMode: 		true,
			variableWidth: 		true,
  			infinite: 			true,
  			slidesToShow: 		1,
		});

		//Home Reviews
		$('.home .reviews').on('beforeChange', function(event, slick, currentSlide, nextSlide){
			var text = (nextSlide+1)+' of '+$('.home .reviews .review').length;
			$('.review-arrows .oftext').html(text);
		});

		$('.home .reviews').slick({
			fade:				true,
			prevArrow:			'<a href="#" class="slick-prev arrow lev-icon-left-arrow"><span class="screen-reader-text">Previous Slide</span></a>',
			nextArrow:			'<a href="#" class="slick-next arrow lev-icon-right-arrow"><span class="screen-reader-text">Next Slide</span></a>',
			speed:				500,
			adaptiveHeight: 	true,
			appendArrows:       $('.review-arrows'),
		});
	}

	function initHasScrolled(){
		// show/hide the header on mobile scroll
		// http://jsfiddle.net/mariusc23/s6mLJ/31/

		setInterval(function() {
			hasScrolled();
		}, 250);
	}

	function hasScrolled() {
	    var st = $(this).scrollTop();

	    // Make sure they scroll more than delta
	    if( Math.abs(lastScrollTop - st) <= delta)
	        return;

	    // If they scrolled down and are past the navbar, add class .nav-up.
	    // This is necessary so you never see what is "behind" the navbar.
	    if ( lastScrollTop && st > lastScrollTop && st > navbarHeight){
	        // Scroll Down
	        header.removeClass('nav-down').addClass('nav-up');
	    } else {
	        // Scroll Up
	        if(st + $(window).height() < $(document).height()) {
	            header.removeClass('nav-up').addClass('nav-down');
	        }
	    }

	    lastScrollTop = st;
	}

	function generalHeightFixes() {
		unifyElementHeights('.blog-preview .title', '.blog-preview-row', {medium: true});
	}
}(jQuery));
